import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../common/common.jsx";
import Header from "../common/Header";
import Footer from "../common/Footer";
//import user from  "./../User.css";

class Landing extends Component {
    render() {
        return (
            <React.Fragment>
                <Header />
                <div className="content-box">
                    <div className="hero-image">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-6 col-md-6">
                                    <div className="slider-text-wrapper">
                                        <h1>
                                            <span>USCG License</span>
                                            <br />
                                            <span>Exam Questions</span>
                                        </h1>
                                        <h6>
                                            Up-to-date questions. Directly
                                            released from U.S. Coast Guard.
                                        </h6>
                                        <div className="slider-btn-wrapper">
                                            <Link
                                                to="./register"
                                                className="btn btn-all my-2 my-sm-0"
                                                type="LOGIN"
                                            >
                                                Study Online
                                            </Link>
                                        </div>
                                        <p className="orr">or</p>
                                        <div className="download-buttons wow fadeInUp">
                                            <Link
                                                to="#"
                                                onClick={() =>
                                                    window.open(
                                                        "https://apps.apple.com/us/app/mariner-license-prep/id1362793782",
                                                        "_blank"
                                                    )
                                                }
                                            >
                                                <img
                                                    src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/appstore.png"
                                                    width="130"
                                                    alt="Download from App Store"
                                                    className="cus_img1"
                                                />
                                            </Link>
                                            <Link
                                                to="#"
                                                onClick={() =>
                                                    window.open(
                                                        "https://play.google.com/store/apps/details?id=com.MarinerLicensePrep.MLP",
                                                        "_blank"
                                                    )
                                                }
                                            >
                                                <img
                                                    src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/playstore.png"
                                                    className="gapper cus_img2"
                                                    width="130"
                                                    alt="Download from Play Store"
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-6">
                                    <div className="slider-img">
                                        <img
                                            className="img-responsive cust_all_img"
                                            src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/new.png"
                                            alt="cust_img"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* video img section */}
                {/* <section id="welcomes" className="container-fluid">
          <div className="eclips-dece">
            <img
              className=""
              src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/dec.png"
              alt="dec"
            />
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-10 col-md-offset-2 col-sm-12">
                <h3 className="cus_txt_20 ">Fully Functional Offline</h3>
                <p className="cus_txt_18 ">
                  Take the IOS App or Android App anywhere you go. Practice USCG
                  Questions relevant to YOUR specific license.
                </p>
                <div className="image-video">
                  <img
                    src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/p6_gold.png"
                    className="img-responsive vm_img"
                    alt="gold"
                  />
                  <video
                    autoPlay="1"
                    loop
                    muted=""
                    poster=""
                    src="https://springboard-cdn.appadvice.com/generated-app-plays/1362793782/132633600-half-no-audio.mp4"
                  ></video>
                </div>
              </div>
            </div>
          </div>
        </section> */}

                <section id="welcomesNew" className="container-fluid">
                    <div className="eclips-dece">
                        <img
                            className=""
                            src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/dec.png"
                            alt="dec"
                        />
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-md-offset-2 col-sm-12">
                                <h3 className="cus_txt_20 ">
                                    Fully Functional Offline
                                </h3>
                                <p className="cus_txt_18 ">
                                    Take the IOS App or Android App anywhere you
                                    go. Practice USCG Questions relevant to YOUR
                                    specific license.
                                </p>
                                <div className="image-video">
                                    <img
                                        src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/p6_gold.png"
                                        className="img-responsive vm_img"
                                        alt="gold"
                                    />
                                    <video
                                        autoPlay="1"
                                        loop
                                        muted=""
                                        poster=""
                                        src="https://springboard-cdn.appadvice.com/generated-app-plays/1362793782/132633600-half-no-audio.mp4"
                                    ></video>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="screens">
                    <div className="eclips-engg">
                        <img
                            src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/eng-n.png"
                            className="cust_all_img"
                            alt="eng-n"
                        />
                    </div>
                    <div className="screen-q">
                        <h2 className="heading">
                            20,000+ <br />
                            Up to Date USCG Questions
                        </h2>
                    </div>
                    <div className="container">
                        <div className="row mb-4">
                            <div className="col-md-6 mb-4">
                                <div className="img-wrap mb-sm-3-my">
                                    <img
                                        className="cust_all_img img-responsive"
                                        src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/DSC-0645.jpg"
                                        alt="sdc-0645df"
                                    />
                                </div>
                            </div>

                            <div className="col-md-6 mb-4">
                                <div className="engin-box-wrap ticker">
                                    <div className="text-wrap">
                                        <h3 className="ttl">
                                            First Assistant Engineer / Chief
                                        </h3>
                                        <ul className="deck-list">
                                            <li>
                                                <Link to="#">Unlimited </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="engin-box-wrap ticker">
                                    <div className="text-wrap ">
                                        <h3 className="ttl">
                                            Third Assistant Engineer / Second{" "}
                                        </h3>
                                        <ul className="deck-list">
                                            <li>
                                                <Link to="#">Unlimited </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                    {/* container over */}
                    {/*<!--Engine-->*/}
                    <div className="container mb-4">
                        <div className="row engine">
                            <div className="col-md-3 mb-4">
                                <div className="engin-box-wrap ">
                                    <div className="text-wrap h-310">
                                        <h3 className="ttl">Chief Engineer </h3>
                                        <ul className="deck-list">
                                            <li>
                                                <Link to="#">Limited</Link>{" "}
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    Uninspected Fishing Industry
                                                    Vessels
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">MODU</Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    OSV Unlimited
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mb-4">
                                <div className="engin-box-wrap ">
                                    <div className="text-wrap h-310">
                                        <h3 className="ttl">
                                            Assistant Engineer{" "}
                                        </h3>
                                        <ul className="deck-list">
                                            <li>
                                                <Link to="#">Limited </Link>{" "}
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    Uninspected Fishing Industry
                                                    Vessels
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">MODU</Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    OSV Unlimited
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mb-4">
                                <div className="engin-box-wrap ">
                                    <div className="text-wrap h-310">
                                        <h3 className="ttl">
                                            Designated Duty Engineer{" "}
                                        </h3>
                                        <ul className="deck-list">
                                            <li>
                                                <Link to="#">Unlimited</Link>{" "}
                                            </li>
                                            <li>
                                                <Link to="#">4000 HP</Link>
                                            </li>
                                            <li>
                                                <Link to="#">1000 HP</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mb-4">
                                <div className="engin-box-wrap">
                                    <div className="text-wrap h-310">
                                        <h3 className="ttl">QMED </h3>
                                        <ul className="deck-list">
                                            <li>
                                                <Link to="#">
                                                    Junior Engineer
                                                </Link>{" "}
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    Electrician- Refrigeration
                                                    Engineer
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Oiler</Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    Fireman - Watertender
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    Machinist - Pumpman
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                    {/* container over */}
                    {/*<!--Deck-->*/}
                    <div className="container">
                        <div className="row deck">
                            <div className="col-md-4 mb-4">
                                <div className="box-wrap">
                                    <div className="img-wrap">
                                        <img
                                            className="cust_all_img"
                                            src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/new_deck.png"
                                            alt="desk-1"
                                        />
                                    </div>
                                    <div className="text-wrap">
                                        <h3 className="ttl">Master</h3>
                                        <ul className="deck-list">
                                            <li>
                                                <Link to="#">
                                                    O/NC, Unlimited{" "}
                                                </Link>{" "}
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    O/NC, 1600/500{" "}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    GRT O/NC, 200 GRT{" "}
                                                </Link>{" "}
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    GL/I, Unlimited{" "}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    GL/I, 1600/500 GRT{" "}
                                                </Link>{" "}
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    GL/I, 200 GRT{" "}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 mb-4">
                                <div className="box-wrap">
                                    <div className="img-wrap">
                                        <img
                                            src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/deck-2.jpeg"
                                            className="cust_all_img"
                                            alt="deck-2"
                                        />
                                    </div>
                                    <div className="text-wrap">
                                        <h3 className="ttl">Mate</h3>
                                        <ul className="deck-list">
                                            <li>
                                                <Link to="#">
                                                    Chief Mate, O/NC, Unl{" "}
                                                </Link>{" "}
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    2nd Mate, O/NC, Unl{" "}
                                                </Link>{" "}
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    3rd Mate, O/NC, Unl{" "}
                                                </Link>{" "}
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    Mate, O/NC, 500/1,600 GRT{" "}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    Mate, GL/I, Unlimited{" "}
                                                </Link>{" "}
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    Mate, GL/I, 500/1,600 GRT
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    Mate, NC, 200 GRT{" "}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    Mate GL/Inland, 200 GRT{" "}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 mb-4">
                                <div className="box-wrap">
                                    <div className="img-wrap">
                                        <img
                                            src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/deck-3.jpg"
                                            className="cust_all_img"
                                            alt="deck-3"
                                        />
                                    </div>
                                    <div className="text-wrap ">
                                        <h3 className="ttl">Charter Boats </h3>
                                        <ul className="deck-list cust_new">
                                            <li>
                                                <Link to="#">
                                                    OUPV Near Coastal{" "}
                                                </Link>{" "}
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    OUPV Great Lakes/inland{" "}
                                                </Link>{" "}
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    Master, NC, 100 GRT{" "}
                                                </Link>{" "}
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    Master, In, 100 GRT{" "}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                    {/* container over */}
                </section>

                {/*<!-- Testimonial -->*/}
                <section className="testimonial">
                    <div className="eclips-dec">
                        <img
                            src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/dec2.png"
                            alt="dec2"
                        />
                    </div>

                    <div className="container">
                        {/* <div className="row"> */}

                        <div
                            id="carouselExampleIndicators"
                            className="carousel slide"
                            data-ride="carousel"
                        >
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div className="carousel-caption">
                                        <div className="carouselimg">
                                            <img
                                                src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/jacob.jpg"
                                                alt="jacob"
                                            />
                                        </div>
                                        <p>
                                            {" "}
                                            Sea Trials' accurate question bank
                                            helped me prepare and pass my
                                            Unlimited Third Assistant Engineer
                                            License on the first try! The cheap
                                            price makes the multiplatform
                                            website and iOS + Android App well
                                            worth it.{" "}
                                        </p>
                                        <h3>
                                            <span>
                                                {" "}
                                                Jacob – Third Assistant Engineer
                                            </span>
                                            <br />
                                            United States Merchant Marine
                                            Academy Graduate
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div className="clearfix"></div>
                            <ol className="carousel-indicators">
                                <li
                                    data-target="#carouselExampleIndicators"
                                    data-slide-to="0"
                                    className="active"
                                ></li>
                            </ol>

                            <Link
                                className="carousel-control-prev"
                                to="#carouselExampleIndicators"
                                role="button"
                                data-slide="prev"
                            >
                                <span
                                    className="carousel-control-prev-icon"
                                    aria-hidden="true"
                                ></span>
                                <span className="sr-only">Previous</span>
                            </Link>
                            <Link
                                className="carousel-control-next"
                                to="#carouselExampleIndicators"
                                role="button"
                                data-slide="next"
                            >
                                <span
                                    className="carousel-control-next-icon"
                                    aria-hidden="true"
                                ></span>
                                <span className="sr-only">Next</span>
                            </Link>
                        </div>

                        {/* </div> */}
                    </div>
                </section>

                <Footer />
            </React.Fragment>
        );
    }
}
export default Landing;
