import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TextFieldArea from "../common/textfield/TextFieldArea";
import { forgotUser } from "../../../actions/userActions.jsx";
//import  logo from "../../.././assets/user/images/logo.png";

class Forgotpassword extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            errors: {},
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors });
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit(e) {
        e.preventDefault();
        const passwordData = {
            email: this.state.email,
        };
        this.props.forgotUser(passwordData, this.props.history);
    }
    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="loginbg">
                    <div className="container">
                        <div className="row">
                            <form
                                className="form-signin"
                                id="login_form"
                                onSubmit={this.onSubmit}
                            >
                                <div className="text-center mb-4">
                                    <Link to="/">
                                        <img
                                            src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/logo.png"
                                            alt="Marinerlicenseprep"
                                            className="login_logo"
                                            width="400"
                                        />
                                    </Link>
                                    <h1 className="forget-pass">
                                        Forgot Password
                                    </h1>

                                    <p>
                                        {" "}
                                        Enter your E-mail to reset your
                                        password.
                                    </p>
                                </div>

                                <div className="form-label-group">
                                    <div className="row">
                                        <TextFieldArea
                                            label="Email Address"
                                            placeholder="Email Address"
                                            name="email"
                                            type="email"
                                            value={this.state.email}
                                            onChange={this.onChange}
                                            error={errors.email}
                                        />
                                    </div>
                                </div>

                                <div className="form-label-group">
                                    <div className="row">
                                        <div className="col-sm-12 col-xs-12">
                                            <button
                                                className="btn btn-lg btn-primary btn-block"
                                                type="submit"
                                            >
                                                Send Reset Link
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <p className="mt-4 mb-3 text-muted text-center">
                                    Don't have account ?{" "}
                                    <Link to="/login">Login</Link>.
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Forgotpassword.propTypes = {
    forgotUser: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    errors: state.errors,
});

export default connect(mapStateToProps, { forgotUser })(Forgotpassword);
