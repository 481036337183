import React, { Component } from "react";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

class Button extends Component {
    constructor() {
        super();
        this.state = {
            sweetAlertInfo: false,
        };
        this.toggleSweetAlert = this.toggleSweetAlert.bind(this);
        this.routeChange = this.routeChange.bind(this);
    }

    toggleSweetAlert(name) {
        switch (name) {
            case "info":
                this.setState({ sweetAlertInfo: !this.state.sweetAlertInfo });
                break;

            default:
                break;
        }
    }

    routeChange() {
        let path = `/user/account/subscription`;
        this.props.history.push(path);
    }

    render() {
        //  const type =  this.props.type;
        const mode = this.props.mode;
        //  const id = this.props.id;
        const num = this.props.num;
        const total_ques = this.props.total_ques;
        var adding = 1;
        var nextnum = num - 0 + (adding - 0);
        var prevnum = num - 1;
        let prevbutton;
        let nextbutton;

        if (this.props.previous) {
            prevbutton = this.props.previous.map((pques) => (
                <span key={pques._id}>
                    {prevnum === 0 ? (
                        <span>
                            {" "}
                            <button
                                type="button"
                                className="backward demo4 disabled"
                                disabled
                            >
                                <i className="fa fa-angle-double-left"></i>{" "}
                                Previous
                            </button>{" "}
                        </span>
                    ) : (
                        <Link
                            to={`/user/bookmarked-question/watch?mode=${mode}&num=${prevnum}&zid=${pques.zmcq_id}`}
                            type="button"
                            className="backward demo4"
                        >
                            <i className="fa fa-angle-double-left"></i> Previous{" "}
                        </Link>
                    )}
                </span>
            ));
        }
        if (total_ques > num) {
            if (this.props.next) {
                nextbutton = this.props.next.map((nques) => (
                    <span key={nques._id}>
                        <Link
                            to={`/user/bookmarked-question/watch?mode=${mode}&num=${nextnum}&zid=${nques.zmcq_id}`}
                            type="button"
                            className="forward adjust-btn demo4"
                        >
                            Next <i className="fa fa-angle-double-right"></i>{" "}
                        </Link>
                    </span>
                ));
            }
        }

        return (
            <React.Fragment>
                {this.state.sweetAlertInfo && (
                    <SweetAlert
                        info
                        showCancel
                        confirmBtnText="Register"
                        confirmBtnBsStyle="info"
                        cancelBtnBsStyle="default"
                        title="Subscription Alerts"
                        onConfirm={this.routeChange}
                        onCancel={() => this.toggleSweetAlert("info")}
                    >
                        To go through more questions today, you must upgrade to
                        premium account.
                    </SweetAlert>
                )}
                <div className="col-md-5 text-right for-only-q">
                    {prevbutton}
                    {nextbutton}
                </div>
            </React.Fragment>
        );
    }
}

export default Button;
