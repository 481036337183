import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getCurrentProfile } from "../../../actions/userActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class Footer extends Component {
    constructor(props) {
        super(props);

        var date = new Date();
        this.state = {
            year: date.getFullYear(),
        };
    }

    componentDidMount() {
        this.props.getCurrentProfile();
    }
    render() {
        const { isAuthenticated1 } = this.props.auth;
        const gustLinks = (
            <li>
                <Link to="/register">Sign Up Free</Link>
            </li>
        );
        const authLinks = (
            <li>
                <Link to="/dashboard">Dashboard</Link>
            </li>
        );
        return (
            <div className="footer footer-user">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-7">
                            <div className="ft-left">
                                <div className="footer-img">
                                    <img
                                        src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/logo.png"
                                        width="200"
                                        alt="Logo"
                                    />
                                </div>
                                <p>
                                    Website & App Study Platform - U.S. Coast
                                    Guard License Exam Preparation
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-5">
                            <div className="ft-right">
                                <ul>
                                    <li>
                                        <Link to="/disclaimer">
                                            Terms and Conditions
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/privacy">Privacy</Link>
                                    </li>
                                    <li>
                                        <Link to="/login">Log In</Link>
                                    </li>
                                    {isAuthenticated1 ? authLinks : gustLinks}
                                    <li>
                                        <Link to="/site-map">Site Map</Link>
                                    </li>
                                    <li>
                                        <Link to="/faq">Faq</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">Contact</Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="#"
                                            onClick={() =>
                                                window.open(
                                                    "https://www.facebook.com/SeaTrials.net/",
                                                    "_blank"
                                                )
                                            }
                                        >
                                            Like us on Facebook
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-xs-12">
                                Copyright © {this.state.year}{" "}
                                <Link to="/">SeaTrials.net</Link> All Rights
                                Reserved.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Footer.propTypes = {
    getCurrentProfile: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { getCurrentProfile })(Footer);
